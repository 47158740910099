import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Tunnels(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./models/environment/tunnels.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="tunnels"
          castShadow
          receiveShadow
          geometry={nodes.tunnels.geometry}
          material={materials.passageTrain}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={6.86}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/environment/tunnels.glb");
