import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function AvionPapier(props) {
  const group = useRef();
  const { nodes, animations } = useGLTF("./models/objects/avionsPapiers.glb");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions["avionEnPapier_01"].play();
    actions["avionEnPapier_02"].play();
    actions["avionPapier_01"].play();
    actions["avionPapier_02"].play();
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="avionEnPapier_01"
          position={[-30.56, 3.59, 25.52]}
          scale={0.7}
        />
        <group
          name="avionEnPapier_02"
          position={[-30.27, 4.32, 25.73]}
          rotation={[Math.PI, -1.37, Math.PI]}
          scale={0.87}
        />
        <mesh
          name="avionPapier_01"
          castShadow
          receiveShadow
          geometry={nodes.avionPapier_01.geometry}
          material={nodes.avionPapier_01.material}
          position={[-30.48, 3.24, 26.94]}
          rotation={[-1.99, 0.26, -1.14]}
          scale={0.45}
        />
        <mesh
          name="avionPapier_02"
          castShadow
          receiveShadow
          geometry={nodes.avionPapier_02.geometry}
          material={nodes.avionPapier_02.material}
          position={[-31.99, 3.88, 25.42]}
          rotation={[-1.19, 0.28, -2.95]}
          scale={0.56}
        />
      </group>
    </group>
  );
}
